import logo from './logo.gif';
import docere from './logos/Docere.png';
import hablame from './logos/Hablame.png';
import parle from './logos/parle.png'
import textAssign from './logos/TextAssign.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import './App.css';

function App() {
  return (
    <div className="App" style={{backgroundColor: "#282728"}}>
      <header className="App-header">
            <BrowserView>
                <img src={logo} style={{ marginTop: "-10%"}}/>
              <div style={{padding:10}}>
                  <img src={parle} style={{width:"18%", marginRight:"1%"}}/>
                  <img src={hablame} style={{width:"15%", marginRight:"1%", marginLeft:"1%"}}/>
                  <img src={textAssign} style={{width:"21%", marginBottom:20,  marginRight:"1%", marginLeft:"1%"}}/>
                  <img src={docere} style={{width:"15%", marginBottom:-80, marginLeft:"1%"}}/>
              </div>
              <p>
                  contact@ddlventures.com
              </p>
                <p style={{fontSize:13, bottom:9, position: "absolute", left: 0, right:0, margin:"auto"}}>DDL Ventures, Inc. © 2020</p>
            </BrowserView>


          <MobileView>
              <img src={logo} style={{ marginTop: "10%", width:"80%"}}/>
              <p>
                  contact@ddlventures.com
              </p>
              <div>
                <img src={textAssign} style={{width:"90%", marginBottom:"10%", marginTop:"10%"}}/><br/>
              </div>
              <div>
                  <img src={hablame} style={{width:"80%", marginBottom:"10%"}}/><br/>
              </div>
              <div>
                  <img src={parle} style={{width:"90%", marginBottom:"10%"}}/><br/>
              </div>
              <div>
                  <img src={docere} style={{width:"90%", marginBottom:"10%"}}/><br/>
              </div>
              <p style={{fontSize:13, bottom:0}}>DDL Ventures, Inc. © 2020</p>
          </MobileView>
      </header>
    </div>
  );
}

export default App;
